<template>
    <div class="OurProducts">
        <!-- <h1>我们的产品</h1> -->
        <img src="https://img.youstarplanet.com/h5/OurProducts/OurProducts1.png" alt="">
        <div>
            <ul class="ul1">
                <li>星动演艺</li>
                <li>国内领先的演艺文化产业数字服务平台</li>
                <li>一点接入，全链无忧，省时省力，省钱省心。</li>
                <li></li>
                <li>星动演艺是深圳市星都时代文化科技有限公司设计研发的深垂直于演艺文化产业的全链数字服务平台及配套后台管理系统。平台提供演艺作品的制作、流通、商务合作、版权管理等全供应链配置服务，以及行业资讯与产业数据链支持，同时，平台内嵌了公司独立设计开发集成的工业级项目管理工具为演艺行业的项目运作管理提供了极大的便利。</li>
                <li>网站：<span>https://www.yanyionline.com/</span></li>
            </ul>
        </div>
        <img src="https://img.youstarplanet.com/h5/OurProducts/OurProducts2.png" alt="">
        <div>
            <ul class="ul2">
                <li>星动视界</li>
                <li>随时随地看演出、逛展览、参加艺术培训</li>
                <li></li>
                <li>星动视界是深圳市星都时代文化科技有限公司设计研发的集演艺、教育、展览、资讯、调研为一体的基层演艺文化内容数字服务平台及配套后台管理系统。随时随地看演出、逛展览、参加艺术普及与培训，在消费者个性化需求及家庭单元化精神文化生活中实现服务内容满足多维度、多层级、多场景需求的同时，真正让获取优质服务的方式更加便利，成本更为实惠。</li>
                <!-- <li>网站：<span>https://www.youstartube.cn/</span></li> -->
            </ul>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.OurProducts{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    &>div{
        padding: 0 14px;
        .ul1{
            margin: 20px 0 51px 0;
            padding: 18px 24px;
            background-color: #F8F8F8;
            li{
                &:nth-child(1){
                    font-size: 18px;
                    font-weight: bold;
                    color: #222222;
                    margin-bottom: 10px;
                }
                &:nth-child(2){
                    font-size: 15px;
                    font-weight: 400;
                    color: #333333;
                }
                &:nth-child(3){
                    margin-top: 6px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }
                &:nth-child(4){
                    width: 36px;
                    height: 6px;
                    background-color: #D00A13;
                    margin-top: 12px;
                    margin-bottom: 26px;
                }
                &:nth-child(5){
                    font-size: 13px;
                    font-weight: 400;
                    color: #606060;
                    line-height: 26px;
                }
                &:nth-child(6){
                    font-size: 13px;
                    font-weight: 400;
                    color: #606060;
                    line-height: 26px;
                    margin-top: 6px;
                    span{
                        color: #619FFE;
                    }
                }
            }
        }
        .ul2{
            margin: 18px 0 112px 0;
            li{
                &:nth-child(1){
                    font-size: 18px;
                    font-weight: bold;
                    color: #222222;
                    margin-bottom: 10px;
                }
                &:nth-child(2){
                    font-size: 15px;
                    font-weight: 400;
                    color: #333333;
                }
                &:nth-child(3){
                    width: 36px;
                    height: 6px;
                    background-color: #D00A13;
                    margin-top: 12px;
                    margin-bottom: 26px;
                }
                &:nth-child(4){
                    font-size: 13px;
                    font-weight: 400;
                    color: #606060;
                    line-height: 26px;
                }
                &:nth-child(5){
                    font-size: 13px;
                    font-weight: 400;
                    color: #606060;
                    line-height: 26px;
                    margin-top: 6px;
                    span{
                        color: #619FFE;
                    }
                }
            }
        }
    }
}
</style>